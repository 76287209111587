<template>
    <div  class="collect_box">
        <!-- 查看订单详情 -->
        请前往APP端进行操作
    </div>
</template>
<script>

export default {
    data() {
        return {
            
        };
    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {
       
    },

}
</script>
<style lang="less" scoped>
.collect_box {
  
}
</style>
