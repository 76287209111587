import { render, staticRenderFns } from "./xq.vue?vue&type=template&id=40e6569c&scoped=true&"
import script from "./xq.vue?vue&type=script&lang=js&"
export * from "./xq.vue?vue&type=script&lang=js&"
import style0 from "./xq.vue?vue&type=style&index=0&id=40e6569c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40e6569c",
  null
  
)

export default component.exports