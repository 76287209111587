<template>
    <div  class="collect_box">
        <div class="colse" @click="cancel">取消</div>
        <div v-show="!voice.type" class="start" >
            <span class="title">单击开始录音，最长可录制60秒</span>
            <img @click="startRecord" width="58" height="58" src="@/assets/yyicon.png" style="margin-bottom: 8px;"/>
            <span>{{ timer.tim }}″</span>
        </div>
        <div  v-show="voice.type && !showCountDown" class="send" @click="recordOver">
            <span class="title">再次单击发送，点空白处取消</span>
            <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M29 57.2363C44.6152 57.2363 57.3887 44.4629 57.3887 28.8477C57.3887 13.2324 44.6152 0.458984 29 0.458984C13.3848 0.458984 0.611328 13.2324 0.611328 28.8477C0.611328 44.4629 13.3848 57.2363 29 57.2363ZM29 55.8008C14.1465 55.8008 2.04688 43.7012 2.04688 28.8477C2.04688 13.9648 14.1465 1.89453 29 1.89453C43.8828 1.89453 55.9531 13.9648 55.9531 28.8477C55.9531 43.7012 43.8828 55.8008 29 55.8008ZM20.8848 39.2773H37.1152C38.5508 39.2773 39.4297 38.3691 39.4297 36.9629V20.7324C39.4297 19.2969 38.5508 18.418 37.1152 18.418H20.8848C19.4785 18.418 18.5703 19.2969 18.5703 20.7324V36.9629C18.5703 38.3691 19.4785 39.2773 20.8848 39.2773Z"
                    fill="#7F7F7F" />
            </svg>
            <span>{{ voice.length + "″" }}</span>
        </div>
        <div v-show="showCountDown" class="send">
            <span class="title">{{ timer.tim }}秒后自动发送</span>
            <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M29 57.2363C44.6152 57.2363 57.3887 44.4629 57.3887 28.8477C57.3887 13.2324 44.6152 0.458984 29 0.458984C13.3848 0.458984 0.611328 13.2324 0.611328 28.8477C0.611328 44.4629 13.3848 57.2363 29 57.2363ZM29 55.8008C14.1465 55.8008 2.04688 43.7012 2.04688 28.8477C2.04688 13.9648 14.1465 1.89453 29 1.89453C43.8828 1.89453 55.9531 13.9648 55.9531 28.8477C55.9531 43.7012 43.8828 55.8008 29 55.8008ZM20.8848 39.2773H37.1152C38.5508 39.2773 39.4297 38.3691 39.4297 36.9629V20.7324C39.4297 19.2969 38.5508 18.418 37.1152 18.418H20.8848C19.4785 18.418 18.5703 19.2969 18.5703 20.7324V36.9629C18.5703 38.3691 19.4785 39.2773 20.8848 39.2773Z"
                    fill="#FF4D4F" />
            </svg>
            <span>{{ voice.length + "″" }}</span>
        </div>
    </div>
</template>
<script>
// import BenzAMRRecorder from 'benz-amr-recorder'
import Recorder from 'recorder-core';
import 'recorder-core/src/engine/mp3';
import 'recorder-core/src/engine/mp3-engine';
export default {
    data() {
        return {
            voice: {
                interval: null, // 录音定时器
                type: 0, // 0未录音 1录音中 2录音完毕 3回放录音
                length: 0, // 录音长度
                src: null // 录音资源
            },
            timer: {
                interval: null,
                tim: 60
            },
            showCountDown: false,
            amrRec: null
        };
    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {
        startRecord() {
            let that = this
            if (that.voice.type === 0) {
                that.amrRec = Recorder({type:"mp3",bitRate: 16,sampleRate: 16000})
                that.amrRec.open(() => {
                        that.amrRec.start();
                        console.log("开始录音")
                        that.voice.type = 1
                        that.benginTimer()
                        //开启录音时长定时器
                        that.voice.interval = setInterval(() => {
                            that.voice.length++
                        }, 1000)
                    },(msg, isUserNotAllow) => {
                        if (isUserNotAllow) {
                            console.log(msg)
                            that.voice.type = 0
                            console.log("录音失败，请检查相关权限和设备")
                        } else {
                            console.log(msg)
                            that.voice.type = 0
                            console.log("录音失败，请检查相关权限和设备")
                        }
                    }
                );
            }
        },
        benginTimer() {
            this.showCountDown = false
            let that = this
            this.timer.interval = setInterval(() => {
                if (that.timer.tim === 0) {
                    clearInterval(that.timer.interval)
                    clearInterval(that.voice.interval)
                    // 自动发送
                    that.recordOver()
                    return
                } else {
                    that.timer.tim--
                }
            }, 1000)
        },
        cancel(){
            let that = this
            if(that.amrRec){
                that.amrRec.close();
                that.amrRec = null;
            }
            clearInterval(that.timer.interval);
            clearInterval(that.voice.interval);
            that.initVocie();
            this.$emit('backAudio');
        },
        recordOver() {
            console.log("停止录音")
   
            this.amrRec.stop((blob, duration) => {
                if (this.voice.length <= 1) {
                    clearInterval(this.timer.interval)
                    clearInterval(this.voice.interval)
                    this.initVocie()
                    // 放弃录音
                    this.amrRec.close();
                    this.amrRec = null;
                    console.log("录音时间较短")
                } else {
                    this.voice.length = Math.round(duration/1000)
                    // 获取音频文件
                    let blobURL = window.URL.createObjectURL(blob);
                    console.log(blobURL)
                    
                    this.voice.src = blob
                    this.$emit('sendAudioMessages', {
                        src: this.voice.src,
                        length: this.voice.length > 60 ? 60 : this.voice.length //一般计时器开始时间都较为提前一秒 减去误差值
                    })
                    clearInterval(this.timer.interval)
                    clearInterval(this.voice.interval)
                    this.amrRec.close();
                    this.amrRec = null;
                    this.initVocie()
                }
            })
        },
        initVocie() {
            this.voice.interval = null
            this.voice.length = 0
            this.voice.type = 0
            this.timer.tim = 60
            this.showCountDown = false
            this.timer.interval = null
        }
    },

}
</script>
<style lang="less" scoped>
.collect_box {
    display: flex;
    flex-direction: column;
    align-items: space-around;
    width: 100%;
    background-color: #fff;
    height: 145px;
    position: absolute;
    bottom: 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    .colse{
        position: absolute;
        width: 80px;
        height: 20px;
        right: 20px;
        top: 10px;
    }
    .start,
    .send {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 10px;
        span {
            margin-bottom: 8px;
        }

        svg {
            margin-bottom: 8px;
        }
    }
}

.collect_box>h3 {
    user-select: none;
    margin-bottom: .03rem;
}

.recordTime {
    margin: .09rem 0;
}

.collect_btn {
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: .8rem;
    height: .8rem;
    border: .03rem solid #474747;
    border-radius: 50%;

}

.collect_btn>img {
    pointer-events: none;
    /* 禁止长按图片保存 */
    width: .6rem;
    height: .6rem;
}

.reacordingStyle {
    width: .5rem;
    height: .5rem;
    background: red;
    border-radius: 50%;
}
</style>
